import { theme } from "@product/scmp-sdk";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";

export const getSubSectionAdsConfigs = (sectionId: string, adZone = "default") => {
  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.Free,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const configs: Array<[AdSlotProps, AdSlotProps]> = [
    // first ads
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec1",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // repeatable ads
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec2",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
  ];

  return configs;
};
