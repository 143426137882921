import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import {
  FirstAds,
  FourthAds,
  SecondAds,
  ThirdAds,
} from "scmp-app/components/section/sub-section/styles";
import type { subSectionAdsQuery$key } from "scmp-app/queries/__generated__/subSectionAdsQuery.graphql";

import { getSubSectionAdsConfigs } from "./ads-config";

const adsComponentMap = [FirstAds, SecondAds, ThirdAds, FourthAds];

export type Props = {
  reference: subSectionAdsQuery$key;
};
export const SubSectionAds: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment subSectionAdsQuery on Query @argumentDefinitions(entityId: { type: "String!" }) {
        subSection: section(filter: { entityId: $entityId }) {
          entityId
          advertZone(version: 2)
        }
      }
    `,
    reference_,
  );

  const adsConfigs = getSubSectionAdsConfigs(
    data.subSection.entityId,
    data?.subSection?.advertZone ?? undefined,
  );

  return (
    <>
      {adsConfigs.map(([desktopConfig, mobileConfig], index) => {
        const ComponentType = adsComponentMap[index];
        return (
          <ComponentType key={`AdSlot${index}`}>
            {desktopConfig && <AdSlot {...desktopConfig} />}
            {mobileConfig && <AdSlot {...mobileConfig} />}
          </ComponentType>
        );
      })}
    </>
  );
};

SubSectionAds.displayName = "SubSectionAds";
