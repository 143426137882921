/**
 * @generated SignedSource<<4267de6519a309c1f637d77e861debea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionQuery$data = {
  readonly articles: {
    readonly " $fragmentSpreads": FragmentRefs<"subSectionIndexArticleList">;
  } | null | undefined;
  readonly parentSection?: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  };
  readonly relatedTopics: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly subSection: {
    readonly entityId: string;
    readonly name: string;
    readonly parentSection: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"commentQueueQuery" | "multimediaQuery" | "sectionNewsletterWidgetQuery" | "subSectionAdsQuery">;
  readonly " $fragmentType": "subSectionQuery";
};
export type subSectionQuery$key = {
  readonly " $data"?: subSectionQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "commentQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": 23,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasParentSection"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "multimediaQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "parentSectionUuid"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "relatedTopicsQueueName"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "scmpPlusPaywallTypeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionQuery",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "subSectionAdsQuery"
    },
    {
      "alias": "subSection",
      "args": [
        {
          "fields": (v0/*: any*/),
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentSection",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "hasParentSection",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "parentSection",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "entityUuid",
                  "variableName": "parentSectionUuid"
                }
              ],
              "kind": "ObjectValue",
              "name": "filter"
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "entityLink"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": "relatedTopics",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "relatedTopicsQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "entityOnelineMenuQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "articles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "kind": "FragmentSpread",
          "name": "subSectionIndexArticleList"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "commentLimit",
          "value": 6
        },
        {
          "kind": "Variable",
          "name": "commentQueueName",
          "variableName": "commentQueueName"
        },
        {
          "kind": "Variable",
          "name": "scmpPlusPaywallTypeIds",
          "variableName": "scmpPlusPaywallTypeIds"
        },
        {
          "kind": "Literal",
          "name": "withHarrysView",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "commentQueueQuery"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "multimediaQueueName",
          "variableName": "multimediaQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "multimediaQuery"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "sectionNewsletterWidgetQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "6601371857b7e9900f3f93eb9c976293";

export default node;
