import styled from "@emotion/styled";
import { fontIndivisibleVariable, theme } from "@product/scmp-sdk";

import { StyledSwiper } from "scmp-app/components/entity-oneline-menu/styles";
import { TopicLink } from "scmp-app/components/topic/topic-link";

export const Container = styled.div`
  margin-block: 20px 40px;
  padding-block: 40px;

  color: #111111;
  font-family: ${fontIndivisibleVariable};

  border-block: 1px solid #777777;
  ${theme.breakpoints.up("tablet")} {
    display: grid;
    grid-template: "name . description" auto "name . explore" auto "name . tags" auto / auto 40px 1fr;

    margin-block: 40px;
  }
`;

export const Name = styled.h1`
  grid-area: name;

  margin-block-end: 40px;

  font-size: 40px;
  font-style: normal;
  line-height: 110%;
  letter-spacing: -2px;
  text-transform: uppercase;
  font-variation-settings: "wght" 300;
  ${theme.breakpoints.up("tablet")} {
    font-size: 48px;
    letter-spacing: -2.4px;
  }
  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 64px;
    letter-spacing: -3.2px;
  }
`;

export const Description = styled.h2`
  grid-area: description;

  display: none;
  justify-self: end;

  margin-block-end: 16px;

  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: end;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 300;
  ${theme.breakpoints.up("tablet")} {
    display: block;

    max-inline-size: 512px;
  }
`;

export const Explore = styled.div`
  grid-area: explore;

  margin-block-end: 16px;

  font-size: 13px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.26px;
  text-transform: uppercase;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 900;

  ${theme.breakpoints.up("tablet")} {
    justify-self: end;

    font-size: 16px;
    letter-spacing: -0.32px;
    text-align: end;
  }
`;

export const Tags = styled.div`
  grid-area: tags;

  inline-size: 100%;

  ${theme.breakpoints.up("tablet")} {
    flex-wrap: wrap;
    justify-content: flex-end;
    justify-self: end;
  }

  ${StyledSwiper} {
    .swiper-button-prev,
    .swiper-button-next {
      block-size: 100%;
    }
    .swiper-wrapper {
      gap: 8px;
    }
    .swiper-slide {
      margin: 0 !important;
    }
    ${theme.breakpoints.up("tablet")} {
      .swiper-wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        justify-content: flex-end;
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }
`;

export const StyledTopicLink = styled(TopicLink)`
  padding-block: 6.4px;
  padding-inline: 12.8px;

  color: #111111;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  letter-spacing: -0.24px;
  text-align: end;
  text-transform: uppercase;

  border: 1px solid #777777;
  border-radius: 2px;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 500;
  ${theme.breakpoints.up("tablet")} {
    padding-block: 8px;
    padding-inline: 16px;

    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.28px;
  }
`;
