import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { Loading } from "scmp-app/components/common/loading";
import { StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import {
  CoverImage as HomePrimaryCoverImage,
  Header as HomePrimaryHeader,
} from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import { Header as HomeSecondaryHeader } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import {
  Primary as SectionTopLeadPrimaryPrimary,
  SectionContainer as SectionTopLeadPrimarySectionContainer,
} from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import {
  Primary as SectionTopWidgetOnePrimary,
  PrimaryRest as SectionTopWidgetOnePrimaryRest,
  StyledContentItemHomeSecondary as SectionTopWidgetOneStyledContentItemHomeSecondary,
} from "scmp-app/components/section/section-top/variant/one/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";
import { StyledContentItemHomePrimary } from "scmp-app/components/top-stories-block/top-stories-item/styles";
import { section as sectionData } from "scmp-app/data/section";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

const largeBorderStyle = css`
  border-block-start: 1px solid rgba(0, 0, 0, 0.2);
`;

const smallBorderStyle = css`
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
`;

const borderStartSmallStyle = css`
  margin-block-start: 20px;
  padding-block-start: 20px;
  ${smallBorderStyle};
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
    ${largeBorderStyle};
  }
`;

const borderStartLargeStyle = css`
  margin-block-start: 32px;
  padding-block-start: 32px;
  ${smallBorderStyle};
  ${theme.breakpoints.up("tablet")} {
    ${largeBorderStyle};
  }
`;

const rowGap = css`
  margin-block-start: 20px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    /* special case for header */
    &:first-child {
      margin-block-start: 24px;
    }
  }
  ${theme.breakpoints.up("homeDesktop")} {
    &:first-child {
      margin-block-start: 32px;
    }
  }
`;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  ${StyledContentItemHomePrimary} {
    display: flex;
    flex-direction: column-reverse;
  }

  ${HomePrimaryCoverImage} {
    margin-block-end: 12px;
    ${StyledFigcaption} {
      display: none;
    }
    ${theme.breakpoints.up("tablet")} {
      margin-block-end: 20px;
    }
  }

  ${HomePrimaryHeader}, ${HomeSecondaryHeader} {
    display: none;
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  inline-size: 100%;
  ${rowGap};
  ${setFullViewportWidthStyles}
`;

export const ContentContainer = styled.div`
  max-inline-size: 1024px;
  margin-inline: auto;
  padding-block-end: 32px;
  padding-inline: 20px;

  ${theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
  ${theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 1272px;
    padding-block-end: 48px;
  }
`;

const getGridLayoutBySection = (parentSectionEntityId: string) => {
  switch (parentSectionEntityId) {
    case sectionData.olympic.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-3 ." min-content
          "top-stories-4 ." min-content
          "subscription-message ." min-content
          "top-stories-5 rhs-module-2" min-content
          "top-stories-5 second-ads" min-content
          "top-stories-5 ." min-content
          "top-stories-6 ." min-content
          "top-stories-rest rhs-module-3" min-content
          "top-stories-rest third-ads" min-content
          "top-stories-rest ." min-content
          "top-stories-rest fourth-ads" min-content
          "newsletter ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule1} {
          display: none;
        }

        ${RHSModule2} {
          margin-block-start: 0;
        }

        ${FourthAds} {
          display: none;
        }
      `;
    default:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 second-ads" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 third-ads" min-content
          "top-stories-4 third-ads" min-content
          "top-stories-4 ." min-content
          "subscription-message ." min-content
          "top-stories-5 rhs-module-3" min-content
          "top-stories-6 rhs-module-3" min-content
          "top-stories-6 fourth-ads" min-content
          "top-stories-rest ." min-content
          "newsletter ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
  }
};

type ContentWrapperProps = {
  $parentSectionEntityId?: string;
};
export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  grid:
    "header" min-content
    "hero-articles" min-content
    "first-ads" min-content
    "top-stories-1" min-content
    "rhs-module-1" min-content
    "second-ads" min-content
    "top-stories-2" min-content
    "rhs-module-2" min-content
    "third-ads" min-content
    "top-stories-3" min-content
    "top-stories-4" min-content
    "subscription-message" min-content
    "top-stories-5" min-content
    "rhs-module-3" min-content
    "fourth-ads" min-content
    "top-stories-6" min-content
    "top-stories-rest" min-content
    "newsletter" min-content
    / minmax(0, 1fr);

  ${theme.breakpoints.up("homeDesktop")} {
    ${props => getGridLayoutBySection(props.$parentSectionEntityId ?? "")};
    column-gap: 32px;
  }

  time {
    line-height: 14px;
  }
`;

export const Header = styled.div`
  grid-area: header;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  padding-block-end: 16px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block-end: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    font-family: ${fontRobotoCondensed};
    line-height: 120%;
    text-transform: uppercase;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 32px;
    }

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 48px;
    }
  }

  ${StyledEntityLink} {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    text-transform: uppercase;

    transition: color 0.2s ease-in-out;

    &:hover {
      color: #2957b5;
    }

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 16px;
    }

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 20px;
    }
  }
`;

export const RelatedTopicContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block-start: 20px;
  margin-block-start: 8px;

  font-weight: 700;
  line-height: 140%;
  white-space: nowrap;
  column-gap: 20px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 20px;
  }
`;

export const HeroArticles = styled.div`
  grid-area: hero-articles;

  margin-block-start: 16px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${SectionTopWidgetOnePrimary} {
    display: flex;
    flex-direction: column-reverse;

    margin-block-end: 0;
    padding-block-end: 0;
    border-block-end: none;
  }
  ${SectionTopLeadPrimarySectionContainer} {
    display: none;
  }
  ${SectionTopLeadPrimaryPrimary} {
    margin-block-end: 16px;
    padding-block-end: 16px;

    ${theme.breakpoints.up("tablet")} {
      margin-block-end: 0;
      padding-block-end: 0;
    }
  }
  ${SectionTopWidgetOnePrimaryRest} {
    ${SectionTopWidgetOneStyledContentItemHomeSecondary} {
      &:not(:last-child) {
        margin-block-end: 16px;
        padding-block-end: 16px;
      }
    }
  }
`;

export const TopStories1 = styled.div`
  grid-area: top-stories-1;

  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;
  }
  ${theme.breakpoints.up("homeDesktop")} {
    ${largeBorderStyle};

    margin-block-start: 32px;
  }
`;

export const TopStories2 = styled.div`
  grid-area: top-stories-2;
  ${borderStartSmallStyle};
`;

export const TopStories3 = styled.div`
  grid-area: top-stories-3;
  ${borderStartSmallStyle};
`;

export const TopStories4 = styled.div`
  grid-area: top-stories-4;
  ${borderStartLargeStyle};
`;

export const TopStories5 = styled.div`
  grid-area: top-stories-5;
  ${borderStartLargeStyle};
  margin-block-start: 46px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
`;

export const TopStories6 = styled.div`
  grid-area: top-stories-6;
  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;

    padding-block-start: 0;
  }
  ${theme.breakpoints.up("homeDesktop")} {
    ${largeBorderStyle};

    padding-block-start: 32px;
  }
`;

export const TopStoriesRest = styled.div`
  grid-area: top-stories-rest;
`;

export const TopStoriesRestArticle = styled.div`
  ${borderStartLargeStyle}
`;

export const RHSModule1 = styled.div`
  grid-area: rhs-module-1;

  ${borderStartSmallStyle}
  ${theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const RHSModule2 = styled.div`
  grid-area: rhs-module-2;

  ${borderStartSmallStyle}
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;
  }
`;

export const RHSModule3 = styled.div`
  grid-area: rhs-module-3;

  margin-block-start: 32px;
`;

export const FirstAds = styled.div`
  grid-area: first-ads;

  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 24px;
  }
  ${theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const SecondAds = styled.div`
  grid-area: second-ads;

  ${rowGap};
`;

export const ThirdAds = styled.div`
  grid-area: third-ads;

  ${rowGap};
`;

export const FourthAds = styled.div`
  grid-area: fourth-ads;

  margin-block-start: 32px;
`;

export const SubscriptionMessage = styled.div`
  grid-area: subscription-message;

  margin-block-start: 32px;
`;

export const Newsletter = styled.div`
  grid-area: newsletter;
  ${borderStartLargeStyle};
`;

export const StyledLoading = styled(Loading)`
  padding-block: 60px;
`;
