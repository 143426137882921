import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { postMagazineSecondaryContent$key } from "scmp-app/queries/__generated__/postMagazineSecondaryContent.graphql";

import {
  Container,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  StyledEntityLink,
  Topic,
} from "./styles";

export type Props = {
  reference: postMagazineSecondaryContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_, topicLinkVariant }, reference) => {
    const content = useFragment(
      graphql`
        fragment postMagazineSecondaryContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageLandscape250x99: true
              withCoverImageSize540x360: true
              withHeadline: true
              withTopicLink: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const topic = providers.topicLink?.();
    const headline = providers.headline({ hideTooltip: true, preferSocialHeadline: true });

    return (
      <Container className={className} ref={reference}>
        <StyledCoverEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "landscape250x99",
                mobileUp: "size540x360",
                tabletUp: "landscape250x99",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>

        <StyledEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <Headline>{headline}</Headline>
        </StyledEntityLink>
        <Topic>{topic}</Topic>
      </Container>
    );
  },
);

Component.displayName = "ContentItemPostMagazineSecondary";

export const ContentItemPostMagazineSecondary = Component;
