/**
 * @generated SignedSource<<08313720262154b00ba304f986327935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionAdsQuery$data = {
  readonly subSection: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentType": "subSectionAdsQuery";
};
export type subSectionAdsQuery$key = {
  readonly " $data"?: subSectionAdsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionAdsQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionAdsQuery",
  "selections": [
    {
      "alias": "subSection",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "version",
              "value": 2
            }
          ],
          "kind": "ScalarField",
          "name": "advertZone",
          "storageKey": "advertZone(version:2)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "45b0f5c8601f9c14c5da24a74c70fbce";

export default node;
