import styled from "@emotion/styled";

import { Loading } from "scmp-app/components/common/loading";
import { Container as HeaderContainer } from "scmp-app/components/post-magazine/header/styles";
import { Container as NewsletterContainer } from "scmp-app/components/post-magazine/post-magazine-newsletter/styles";
import {
  ArticlesContainer,
  Container as TopStoriesContainer,
} from "scmp-app/components/post-magazine/top-stories/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  ${setFullViewportWidthStyles}
`;

export const Wrapper = styled.div`
  max-inline-size: 1280px;
  margin-inline: auto;
  padding-inline: 20px;

  ${HeaderContainer} {
    padding-inline: 0;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 40px;

    ${HeaderContainer} {
      padding-inline: 0;
    }
  }

  & > ${TopStoriesContainer} {
    &:last-child {
      ${ArticlesContainer}:last-of-type {
        margin-block-end: 40px;
        border-block-end: 0;

        padding-block-end: 0;
        ${props => props.theme.breakpoints.up("tablet")} {
          margin-block-end: 64px;
        }
        ${props => props.theme.breakpoints.up("homeDesktop")} {
          margin-block-end: 40px;
        }
      }
    }
  }
`;

export const StyledLoading = styled(Loading)`
  padding-block: 60px;
`;

export const NewsletterDivider = styled.div`
  border-block-start: 1px solid #777777;
`;

export const Newsletter = styled.div`
  display: flex;
  justify-content: center;

  inline-size: 100%;
  ${NewsletterContainer} {
    max-inline-size: 1280px;
  }
`;
